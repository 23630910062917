import { nullifyWhenEmptyArray, convertPriceRange } from '@lifedot/utils'
import { AttributeCode, Condition } from '@lifedot/constants/searchChoices'
import { defaultSort, Sort, sorts } from '@lifedot/constants/sort'
import { ParsedUrlQuery } from 'querystring'

interface PriceRange {
  max: number | null
  min: number | null
}

export interface SearchParams {
  cityIds: Array<number> | null
  stationIds: Array<number> | null
  cemeteryTypes: Array<string> | null
  sectionTypes: Array<string> | null
  sects: Array<string> | null
  sectionCapacities: Array<string> | null
  facilities: Array<string> | null
  features: Array<string> | null
  priceMin: string | null
  priceMax: string | null
  priceRange: PriceRange | null
  conditions: Array<{ attributeCode: string; code: string }>
  from: number
  sort: Sort
}

const PER_PAGE = 20 as const

export const getSearchParamsByQuery = (
  query: ParsedUrlQuery,
  cityId?: undefined | number,
  stationId?: undefined | number,
  condition?: undefined | null | Condition,
  options?: {
    defaultSort?: string
  }
): SearchParams => {
  const cityIds = nullifyWhenEmptyArray([
    ...(getParams('city_ids', query) || []).map(Number),
    ...(cityId ? [cityId] : [])
  ])
  const stationIds = nullifyWhenEmptyArray([
    ...(getParams('station_ids', query) || []).map(Number),
    ...(stationId ? [stationId] : [])
  ])
  const cemeteryTypes = nullifyWhenEmptyArray([
    ...(getParams(AttributeCode.CEMETERY_TYPE, query) || []),
    ...(condition?.attributeCode === AttributeCode.CEMETERY_TYPE
      ? [condition.code]
      : [])
  ])
  const sectionTypes = nullifyWhenEmptyArray([
    ...(getParams(AttributeCode.SECTION_TYPE, query) || []),
    ...(condition?.attributeCode === AttributeCode.SECTION_TYPE
      ? [condition.code]
      : [])
  ])
  const sects = nullifyWhenEmptyArray([
    ...(getParams(AttributeCode.SECT, query) || []),
    ...(condition?.attributeCode === AttributeCode.SECT ? [condition.code] : [])
  ])
  const sectionCapacities = nullifyWhenEmptyArray([
    ...(getParams(AttributeCode.SECTION_CAPACITY, query) || []),
    ...(condition?.attributeCode === AttributeCode.SECTION_CAPACITY
      ? [condition.code]
      : [])
  ])
  const facilities = nullifyWhenEmptyArray([
    ...(getParams(AttributeCode.FACILITY, query) || []),
    ...(condition?.attributeCode === AttributeCode.FACILITY
      ? [condition.code]
      : [])
  ])
  const features = nullifyWhenEmptyArray([
    ...(getParams(AttributeCode.FEATURE, query) || []),
    ...(condition?.attributeCode === AttributeCode.FEATURE
      ? [condition.code]
      : [])
  ])
  const priceMin = getSingleParam(AttributeCode.PRICE_MIN, query)
  const priceMax = getSingleParam(AttributeCode.PRICE_MAX, query)
  const priceRange =
    priceMax || priceMin
      ? {
          max: convertPriceRange(priceMax),
          min: convertPriceRange(priceMin)
        }
      : null

  const conditions = [
    ...(cemeteryTypes?.map((code) => ({
      attributeCode: AttributeCode.CEMETERY_TYPE,
      code
    })) ?? []),
    ...(sectionTypes?.map((code) => ({
      attributeCode: AttributeCode.SECTION_TYPE,
      code
    })) ?? []),
    ...(sects?.map((code) => ({ attributeCode: AttributeCode.SECT, code })) ??
      []),
    ...(sectionCapacities?.map((code) => ({
      attributeCode: AttributeCode.SECTION_CAPACITY,
      code
    })) ?? []),
    ...(facilities?.map((code) => ({
      attributeCode: AttributeCode.FACILITY,
      code
    })) ?? []),
    ...(features?.map((code) => ({
      attributeCode: AttributeCode.FEATURE,
      code
    })) ?? []),
    ...(priceMin
      ? [{ attributeCode: AttributeCode.PRICE_MIN, code: priceMin }]
      : []),
    ...(priceMax
      ? [{ attributeCode: AttributeCode.PRICE_MAX, code: priceMax }]
      : [])
  ]

  const _page = query['page']
  const page = Number(Array.isArray(_page) ? _page[0] : _page) ?? 1
  const from = ((Number.isInteger(page) ? page : 1) - 1) * PER_PAGE

  const _sort = query['order']
  const sortKey = (Array.isArray(_sort) ? _sort[0] : _sort) ?? ''
  const sort =
    sorts[sortKey] ?? sorts[options?.defaultSort ?? 'default'] ?? defaultSort

  return {
    cityIds,
    stationIds,
    cemeteryTypes,
    sectionTypes,
    sects,
    sectionCapacities,
    facilities,
    features,
    priceMin,
    priceMax,
    priceRange,
    conditions,
    from,
    sort
  }
}

const getParams = (key: string, query: ParsedUrlQuery) => {
  const target = query[`f[${key}][]`]
  if (!target) return null
  return Array.isArray(target) ? target : [target]
}

const getSingleParam = (key: string, query: ParsedUrlQuery): string | null => {
  const target = query[`f[${key}]`]
  if (!target) return null
  return Array.isArray(target) ? target[0] ?? '' : target
}
