import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  pc: css({
    [mq('sp')]: {
      display: 'none'
    }
  }),
  sp: css({
    display: 'none',
    [mq('sp')]: {
      display: 'block'
    }
  })
}

type ResponsiveContentsProps = {
  desktopContents: ReactNode
  mobileContents: ReactNode
}

export const ResponsiveContents: FC<ResponsiveContentsProps> = ({
  desktopContents,
  mobileContents
}) => {
  return (
    <>
      <div css={styles.pc}>{desktopContents}</div>
      <div css={styles.sp}>{mobileContents}</div>
    </>
  )
}
