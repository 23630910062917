/**
 * @generated SignedSource<<e22dccef1c7ac0381716c5c8a12c7060>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ListPageContextProvider_station$data = {
  readonly stationId: number
  readonly name: string
  readonly cityId: number
  readonly cityName: string
  readonly cityCemeteryCount: number
  readonly railwayLineName: string
  readonly designatedCityId: number | null
  readonly designatedCityName: string | null
  readonly prefectureName: string
  readonly prefectureRoma: string
  readonly ' $fragmentType': 'ListPageContextProvider_station'
}
export type ListPageContextProvider_station$key = {
  readonly ' $data'?: ListPageContextProvider_station$data
  readonly ' $fragmentSpreads': FragmentRefs<'ListPageContextProvider_station'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ListPageContextProvider_station',
  selections: [
    {
      alias: 'stationId',
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cityId',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cityName',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cityCemeteryCount',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'railwayLineName',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'designatedCityId',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'designatedCityName',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'prefectureName',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'prefectureRoma',
      storageKey: null
    }
  ],
  type: 'StationWithCityCemeteryCount',
  abstractKey: null
}

;(node as any).hash = '30a2995bf3efae49068f58fc474af88d'

export default node
