/**
 * @generated SignedSource<<75afdf36a1f183621a9877713b54bdf9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ListPageContextProvider_pagination$data = {
  readonly total: number
  readonly pagination: {
    readonly currentPageNumber: number
    readonly hasNext: boolean
    readonly hasPrev: boolean
    readonly lastPageNumber: number
    readonly perPageSize: number
    readonly from: number
    readonly to: number
  }
  readonly ' $fragmentType': 'ListPageContextProvider_pagination'
}
export type ListPageContextProvider_pagination$key = {
  readonly ' $data'?: ListPageContextProvider_pagination$data
  readonly ' $fragmentSpreads': FragmentRefs<'ListPageContextProvider_pagination'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ListPageContextProvider_pagination',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'total',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'PaginationStatus',
      kind: 'LinkedField',
      name: 'pagination',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'currentPageNumber',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasNext',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasPrev',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'lastPageNumber',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'perPageSize',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'from',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'to',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'CemeteryResult',
  abstractKey: null
}

;(node as any).hash = '0dd9d8dce6e06a00cf4dcaad81db7097'

export default node
