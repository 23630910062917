/**
 * @generated SignedSource<<ba9407d472840f1dbbb3cd9df279980a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ListPageContextProvider_city$data = {
  readonly cityId: number
  readonly name: string
  readonly designatedCity: {
    readonly name: string
    readonly designatedCityId: number
  } | null
  readonly isWard: boolean
  readonly isDesignated: boolean
  readonly prefectureName: string
  readonly prefectureRoma: string
  readonly ' $fragmentType': 'ListPageContextProvider_city'
}
export type ListPageContextProvider_city$key = {
  readonly ' $data'?: ListPageContextProvider_city$data
  readonly ' $fragmentSpreads': FragmentRefs<'ListPageContextProvider_city'>
}

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'name',
    storageKey: null
  }
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'ListPageContextProvider_city',
    selections: [
      {
        alias: 'cityId',
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null
      },
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'CitySimple',
        kind: 'LinkedField',
        name: 'designatedCity',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: 'designatedCityId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'isWard',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'isDesignated',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'prefectureName',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'prefectureRoma',
        storageKey: null
      }
    ],
    type: 'City',
    abstractKey: null
  }
})()

;(node as any).hash = 'f91eadc9c84fd547fac15e475df8da42'

export default node
