import {
  createContext,
  FC,
  ReactNode,
  useMemo,
  memo,
  PropsWithChildren
} from 'react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import {
  ListPageContextProvider_city$data,
  ListPageContextProvider_city$key
} from '@/hooks/use-list-page/__generated__/ListPageContextProvider_city.graphql'
import {
  ListPageContextProvider_station$data,
  ListPageContextProvider_station$key
} from '@/hooks/use-list-page/__generated__/ListPageContextProvider_station.graphql'
import {
  ListPageContextProvider_pagination$data,
  ListPageContextProvider_pagination$key
} from '@/hooks/use-list-page/__generated__/ListPageContextProvider_pagination.graphql'
import { allSearchChoices, Condition } from '@lifedot/constants/searchChoices'
import { defaultSort } from '@lifedot/constants/sort'
import { useRouter } from 'next/router'
import {
  getSearchParamsByQuery,
  SearchParams
} from '@/hooks/use-list-page/dependencies'
import { ParsedUrlQuery } from 'querystring'
import equal from 'fast-deep-equal'

export interface Prefecture {
  roma: string
  name: string
}

export interface ListPageContextValue {
  prefecture: Prefecture
  city: ListPageContextProvider_city$data | null
  station: ListPageContextProvider_station$data | null
  condition: Condition | null
  pagination: ListPageContextProvider_pagination$data['pagination']
  total: number
  searchParams: Omit<SearchParams, 'from'>
}

export const ListPageContext = createContext<ListPageContextValue>({
  prefecture: { roma: '', name: '' },
  city: null,
  station: null,
  condition: null,
  pagination: {
    currentPageNumber: 1,
    hasNext: false,
    hasPrev: false,
    lastPageNumber: 1,
    perPageSize: 20,
    from: 0,
    to: 0
  },
  searchParams: {
    cityIds: null,
    stationIds: null,
    cemeteryTypes: null,
    sectionTypes: null,
    sects: null,
    sectionCapacities: null,
    facilities: null,
    features: null,
    priceMin: null,
    priceMax: null,
    priceRange: null,
    conditions: [],
    sort: defaultSort
  },
  total: 0
})

interface ListPageContextProviderProps {
  prefecture: Prefecture
  city?: ListPageContextProvider_city$key | null
  station?: ListPageContextProvider_station$key | null
  conditionCode?: string | null
  defaultSort?: string
  pagination: ListPageContextProvider_pagination$key
}

export const ListPageContextProvider: FC<
  PropsWithChildren<ListPageContextProviderProps>
> = ({
  prefecture,
  city = null,
  station = null,
  conditionCode = null,
  pagination,
  defaultSort,
  children
}) => {
  const cityData = useFragment(cityFragment, city)
  const stationData = useFragment(stationFragment, station)
  const { pagination: paginationData, total } = useFragment(
    paginationFragment,
    pagination
  )
  const condition = useMemo(
    () => allSearchChoices.find((item) => item.code === conditionCode) ?? null,
    [conditionCode]
  )

  const { query, pathname } = useRouter()
  const searchParams = useMemo(
    () =>
      getSearchParamsByQuery(
        query,
        cityData?.cityId,
        stationData?.stationId,
        condition,
        {
          defaultSort
        }
      ),
    [cityData?.cityId, condition, defaultSort, query, stationData?.stationId]
  )

  return (
    <MemorizedListPageContextProvider
      value={{
        prefecture,
        city: cityData,
        station: stationData,
        condition,
        pagination: paginationData,
        searchParams: searchParams,
        total
      }}
      query={query}
      pathname={pathname}
    >
      {children}
    </MemorizedListPageContextProvider>
  )
}

type BeforeMemorizedListPageContextProviderProps = {
  children: ReactNode
  value: ListPageContextValue
  query: ParsedUrlQuery
  pathname: string
}

const BeforeMemorizedListPageContextProvider: FC<
  PropsWithChildren<BeforeMemorizedListPageContextProviderProps>
> = (props) => <ListPageContext.Provider {...props} />

const MemorizedListPageContextProvider = memo(
  BeforeMemorizedListPageContextProvider,
  (prev, current) =>
    prev.pathname === current.pathname && equal(prev.query, current.query)
)

const cityFragment = graphql`
  fragment ListPageContextProvider_city on City {
    cityId: id
    name
    designatedCity {
      name
      designatedCityId: id
    }
    isWard
    isDesignated
    prefectureName
    prefectureRoma
  }
`

const stationFragment = graphql`
  fragment ListPageContextProvider_station on StationWithCityCemeteryCount {
    stationId: id
    name
    cityId
    cityName
    cityCemeteryCount
    railwayLineName
    designatedCityId
    designatedCityName
    prefectureName
    prefectureRoma
  }
`

const paginationFragment = graphql`
  fragment ListPageContextProvider_pagination on CemeteryResult {
    total
    pagination {
      currentPageNumber
      hasNext
      hasPrev
      lastPageNumber
      perPageSize
      from
      to
    }
  }
`
